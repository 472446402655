import { Injectable } from '@angular/core';
import { AuthService } from 'app/@core/auth/auth.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  api = `${environment.api.endpoint}`;

  constructor(
    private _authService: AuthService,
    private _http: HttpClient) { }

  async getStaff(): Promise<any> {
    const header = await this._authService.getAuthorizatonHeader();
    const filter = JSON.stringify({ include: 'role' });

    return this._http.get(`${this.api}/Staff?filter=${filter}`, header)
    .toPromise();
  }

  async getMember(id: number): Promise<any> {
    const header = await this._authService.getAuthorizatonHeader();

    return this._http.get(`${this.api}/Staff/${id}`, header)
      .toPromise();
  }

  async createStaffMember(data: any): Promise<any> {
    data.password = 'changeme';
    return this._http.post(`${this.api}/Staff`, data)
      .toPromise();
  }

  async updateStaffMember(data: any): Promise<any> {
    const header = await this._authService.getAuthorizatonHeader();

    return this._http.patch(`${this.api}/Staff/${data.id}`, data, header)
      .toPromise();
  }

  async deleteStaffMember(id: number): Promise<any> {
    return this._http.delete(`${this.api}/Staff/${id}`)
      .toPromise();
  }
}
