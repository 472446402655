import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-product-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input() source: any;

  settings = {
    actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      id: { title: 'ID', width: '50px'},
      sku: { title: 'SKU', width: '200px' },
      name: { title: 'Name', class: 'product-name' }
    }
  };

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  showDetails(event) {
    this._router.navigateByUrl(`/pages/product/${event.data.id}/details`);
  }

}
