import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { Ng2SmartTableModule } from 'ngx-smart-table';

@NgModule({
  declarations: [ListComponent],
  imports: [
    CommonModule,
    Ng2SmartTableModule
  ],
  exports: [
    ListComponent
  ]
})
export class ComponentsModule { }
