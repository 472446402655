import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  api = `${environment.api.endpoint}`;

  constructor(
    private _http: HttpClient
  ) { }

  async getSuppliers(): Promise<any> {
    const filter = JSON.stringify({
      order: 'name ASC'
    });

    return this._http.get(`${this.api}/Suppliers?filter=${filter}`)
      .toPromise();
  }
}
