import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../@core/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParentService {

  api = `${environment.api.endpoint}`;

  constructor(
    private _auth: AuthService,
    private _http: HttpClient
  ) { }

  async getParents(): Promise<any> {
    const token = await this._auth.getAuthorizatonHeader();
    const headers = new HttpHeaders().set('access-token', token);
    const filter = JSON.stringify({
      limit: 20,
      order: 'id DESC'
    });

    return this._http.get(`${this.api}/parents?filter=${filter}`, { headers: headers })
      .toPromise();
  }
}
